import React from "react";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Container = styled.div`
    padding: 1rem 0;
`;

const Header = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;

    @media screen and (max-width: 786px) {
        font-size: 1.2rem;
    }
`;

const Subheader = styled.p`
    margin-top: 0.5rem;
    font-size: 1rem;

    @media screen and (max-width: 786px) {
        font-size: 0.8rem;
    }
`;

const Row = styled.div`
    display: flex;
`;

const Fill = styled.div`
    flex: 1;
`;

const ImageContainer = styled.div`
    width: 128px;
    height: 128px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    flex: 0 0 128px;

    @media screen and (max-width: 786px) {
        width: 64px;
        height: 64px;
        flex: 0 0 64px;
    }
`;

const TextContainer = styled.div`
    padding-left: 2rem;
    padding-top: 1.2rem;

    @media screen and (max-width: 786px) {
        padding-left: 1rem;
        padding-top: 0;
    }
`;

const Image = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default ({ data }) => {
    return (<Container>
        <Row>
            <ImageContainer>
                <Image />
            </ImageContainer>
            <TextContainer>
                <Header>{data.fullName}</Header>
                <Subheader>{data.title}</Subheader>
                <Subheader>{data.email}</Subheader>
                <Subheader>
                    <a href="https://github.com/Vengarioth">github.com/vengarioth</a>
                </Subheader>
            </TextContainer>
        </Row>
    </Container>);
};
