import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
    padding: 1rem 0;
`;

const Header = styled.h2`
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: .5px solid rgba(0,0,0,0.1);
`;

const SectionContainer = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    break-inside: avoid;
`;

const SectionHeader = styled.h3`
    font-size: 1.4rem;
    font-family: 'Roboto', sans-serif;
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
`;

const SkillList = styled.ul`
    padding-left: 1rem;
`;

const SkillListEntry = styled.li`
    padding: 0.2rem 0;
`;

const Row = styled.div`
    display: flex;
`;
const Fill = styled.div`
    flex: 1;
`;

const Dots = styled.div`
    display: flex;
`;
const EmptyDot = styled.div`
    border-radius: 50%;
    border: 1px solid black;
    width: 12px;
    height: 12px;
    margin: 2px;
`;
const FilledDot = styled.div`
    border-radius: 50%;
    border: 1px solid black;
    background: black;
    width: 12px;
    height: 12px;
    margin: 2px;

    @media print {
        /* hack to make dots filled in print */
        box-shadow: inset 0 0 0 16px black;
    }
`;

function makeDots(amount, max = 5) {
    const dots = [];
    for (let i = 0; i < amount; i++) {
        dots.push(<FilledDot key={i} />);
    }
    for (let i = amount; i < max; i++) {
        dots.push(<EmptyDot key={i} />);
    }
    return dots;
}

export default ({ data }) => {
    return (<Container>
        <Header>Skills</Header>
        {data.map((section, i) => <SectionContainer key={i}>
            <SectionHeader>{section.section}</SectionHeader>
            <SkillList>
                {section.skills.map((skill, i) => <SkillListEntry key={i}>
                    <Row>
                        {skill.name}
                        <Fill />
                        <Dots>
                            {makeDots(parseInt(skill.value))}
                        </Dots>
                    </Row>
                </SkillListEntry>)}
            </SkillList>
        </SectionContainer>)}
    </Container>);
};
