import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";

import "../style/reset.css";
import data from "../../data.json";

import Experience from '../components/experience';
import Skills from '../components/skills';
import Details from '../components/details';
import Achievements from '../components/achievements';

const Layout = styled.div`
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 1200px;

    padding: 2rem;

    background: #fff;

    font-family: 'Roboto', sans-serif;
    box-shadow: 0 1px 2px rgba(0,0,0,0.5);

    @media screen and (max-width: 786px) {
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.5rem;
        box-shadow: none;
    }

    @media print {
        box-shadow: none;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2 2 600px;

    padding: 1rem 2rem;
`;

const ColumnSmall = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1 300px;

    padding: 1rem 2rem;
`;

const Section = styled.section`
`;

export default () => {
    return (<Layout>
        <Helmet title={data.title} defer={false} />
        <Row>
            <Column>
                <Details data={data.details} />
            </Column>
        </Row>
        <Row>
            <Column>
                <Experience data={data.experience} />
            </Column>
            <ColumnSmall>
                <Skills data={data.skills} />
            </ColumnSmall>
        </Row>
    </Layout>);
};
