import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
    padding: 1rem 0;
`;

const Header = styled.h2`
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: .5px solid rgba(0,0,0,0.1);
`;

const ExperienceList = styled.ul`
`;

const ExperienceListEntry = styled.li`
    padding-top: 1rem;
    padding-bottom: 2rem;
    break-inside: avoid;
`;

const ExperienceListEntryHeader = styled.h3`
    font-size: 1.4rem;
    font-family: 'Roboto', sans-serif;
`;

const DateSpan = styled.span`
    @media screen and (max-width: 786px) {
        padding-top: 0.4rem;
    }
    white-space: nowrap;
`;

const HorizontalList = styled.div`
    display: flex;
    align-items: baseline;

    @media screen and (max-width: 786px) {
        flex-direction: column;
    }
`;

const Fill = styled.div`
    flex: 1 1;
`;

const EntryTitle = styled.h4`
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;

    padding-top: 0.4rem;
    padding-bottom: 0.6rem;
`;

const SubSection = styled.ul`
    padding-left: 1rem;
`;

const Description = styled.p`
    margin-bottom: 1rem;
`;

export default ({data}) => {
    return (<Container>
        <Header>{'Experience'}</Header>
        <ExperienceList>
            {data.map((experience, i) => <ExperienceListEntry key={i}>
                <HorizontalList>
                    <ExperienceListEntryHeader>{experience.name}</ExperienceListEntryHeader>
                    <Fill />
                    <DateSpan>
                        {`${experience.from} - ${experience.until}`}
                    </DateSpan>
                </HorizontalList>
                <SubSection>
                    {experience.roles.map((role, i) =>
                        <li key={i}>
                            <EntryTitle>{role.role}</EntryTitle>
                            <Description>{role.description}</Description>
                        </li>
                    )}
                </SubSection>
                <SubSection>
                    {experience.projects.map((project, i) =>
                        <li key={i}>
                            <EntryTitle>{project.name}</EntryTitle>
                            <Description>{project.description}</Description>
                        </li>
                    )}
                </SubSection>
            </ExperienceListEntry>)}
        </ExperienceList>
    </Container>);
};
