import React from "react";
import styled from "@emotion/styled";

const Header = styled.h2`
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: .5px solid rgba(0,0,0,0.1);
`;

const Container = styled.div`
`;

const AchievementList = styled.ul`
`;

const AchievementListEntry = styled.li`
`;

const Text = styled.p`
    padding: 0.4rem 0;
`;

export default ({ data }) => {
    return (<Container>
        <Header>Career Achievements</Header>
        <AchievementList>
            {data.map((achievement, i) => (
                <AchievementListEntry key={i}>
                    <Text>{achievement}</Text>
                </AchievementListEntry>
            ))}
        </AchievementList>
    </Container>);
};
